import { Injectable } from '@angular/core';

const SCHEDULAR_SERVICE_APIS_BASE_PATH = `/epmsproxy/api/appointments/v1`;
const SIMPLIFIED_SCHEDULAR_SERVICE_APIS_BASE_PATH = `/epmsproxy/booking-simplified/api/appointments/v1`;

@Injectable()
export class Config {
	contactInfo = {
		phone: `8003628671`,
		phone_dots: `800.362.8671`,
		phone_prefix_1: `18003628671`,
		phone_prefix_1_dots: `1.800.362.8671`,
		phone_prefix_plus1: `+18003628671`,
		phone_prefix_plus1_dots: `+1.800.362.8671`,
		email_MemberServices: `MemberServices@ehe.health`
	};

	url = {
		schedulingApi: {
			getLocation: '/epmsproxy/api/locations',
			getProcedure: '/epmsproxy/api/schedule/procedures',
			getAvailableSlots: '/epmsproxy/api/schedule/available-slots',
			getStates: '/epmsproxy/api/locations/states',
			getAlternateLocations: '/epmsproxy/api/locations/alternative',
			getStaticAptData: '/epmsproxy/api/appointments/static-data',
			getUpcomingAppointments: '/epmsproxy/api/appointments/upcoming',
			getPastAppointments: '/epmsproxy/api/appointments/past',
			getBookingReasons: '/epmsproxy/api/schedule/booking-reasons',
			confirmPreBookingAppointment: '/epmsproxy/api/appointments/confirm-pre-booking/',
			selectSlot: '/epmsproxy/api/appointments',
			getCancelledAppointmentDetails: '/epmsproxy/api/appointments',
			cancelAppointment: '/epmsproxy/api/appointments/cancel',
			getBookingPreCheckMsg: '/epmsproxy/api/schedule/booking-pre-check-message',
			messageResponse: '/epmsproxy/api/schedule/booking-pre-check-message/',
			requestSlot: '/epmsproxy/api/appointments/request',
			getIncentives: '/epmsproxy/api/appointments/incentives',
			getRescheduleProcedures: '/epmsproxy/api/appointments',
			getBookingSimplified: '/epmsproxy/api/appointments/defaults',
			saveCarServicePreferences: '/epmsproxy/api/appointments/{0}/preference',
			getAppoinmentDetails: '/epmsproxy/api/appointments/{0}',
			getAppointmentDataForToaster: '/epmsproxy/public/api/appointments/toaster-data',
			incentiveTeaserInfo: '/epmsproxy/api/appointments/incentives/teaser',
			getAlternateAvailableSlots: '/epmsproxy/api/schedule/alternate-available-slots',
			verifyReferalCode: '/epmsproxy/api/appointments/incentives/verify',
			getProvidersSlots: `${SCHEDULAR_SERVICE_APIS_BASE_PATH}/providersSlots`,
			getProviders: `${SCHEDULAR_SERVICE_APIS_BASE_PATH}/providers`,
			getProviderSlots: `${SCHEDULAR_SERVICE_APIS_BASE_PATH}/providerSlots`,
			getMoreAvailability: `${SCHEDULAR_SERVICE_APIS_BASE_PATH}/moreAvailability`,
		},
		simplifiedSchedulingApi: {
			getLocation: '/epmsproxy/booking-simplified/api/locations',
			getProcedure: '/epmsproxy/booking-simplified/api/schedule/procedures',
			getAvailableSlots: '/epmsproxy/booking-simplified/api/schedule/available-slots',
			getStates: '/epmsproxy/booking-simplified/api/locations/states',
			getAlternateLocations: '/epmsproxy/booking-simplified/api/locations/alternative',
			getUpcomingAppointments: '/epmsproxy/booking-simplified/api/appointments/upcoming',
			getPastAppointments: '/epmsproxy/booking-simplified/api/appointments/past',
			getBookingReasons: '/epmsproxy/booking-simplified/api/schedule/booking-reasons',
			confirmPreBookingAppointment: '/epmsproxy/booking-simplified/api/appointments/confirm-pre-booking/',
			selectSlot: '/epmsproxy/booking-simplified/api/appointments',
			getCancelledAppointmentDetails: '/epmsproxy/booking-simplified/api/appointments',
			cancelAppointment: '/epmsproxy/booking-simplified/api/appointments/cancel',
			getBookingPreCheckMsg: '/epmsproxy/booking-simplified/api/schedule/booking-pre-check-message',
			messageResponse: '/epmsproxy/booking-simplified/api/schedule/booking-pre-check-message/',
			requestSlot: '/epmsproxy/booking-simplified/api/appointments/request',
			getIncentives: '/epmsproxy/booking-simplified/api/appointments/incentives',
			getRescheduleProcedures: '/epmsproxy/booking-simplified/api/appointments',
			getBookingSimplified: '/epmsproxy/booking-simplified/api/appointments/defaults',
			saveCarServicePreferences: '/epmsproxy/booking-simplified/api/appointments/{0}/preference',
			getAppoinmentDetails: '/epmsproxy/booking-simplified/api/appointments/{0}',
			getAppointmentDataForToaster: '/epmsproxy/public/api/appointments/toaster-data',
			covid19PreScreening: '/epmsproxy/booking-simplified/api/appointments/covid19-pre-screening',
			incentiveTeaserInfo: '/epmsproxy/booking-simplified/api/appointments/incentives/teaser',
			getAlternateAvailableSlots: '/epmsproxy/booking-simplified/api/schedule/alternate-available-slots',
			verifyReferalCode: '/epmsproxy/booking-simplified/api/appointments/incentives/verify',
			getProvidersSlots: `${SIMPLIFIED_SCHEDULAR_SERVICE_APIS_BASE_PATH}/providersSlots`,
			getProviders: `${SIMPLIFIED_SCHEDULAR_SERVICE_APIS_BASE_PATH}/providers`,
			getProviderSlots: `${SIMPLIFIED_SCHEDULAR_SERVICE_APIS_BASE_PATH}/providerSlots`,
			getMoreAvailability: `${SIMPLIFIED_SCHEDULAR_SERVICE_APIS_BASE_PATH}/moreAvailability`,
		},
		registrationApi: {
			baseApi: '/epmsproxy/public/api/members',
			createAccount: '/epmsproxy/public/api/members',
			migrateAccount: '/epmsproxy/public/api/members/migrate',
			getEmployers: '/epmsproxy/public/api/employers',
			getEmployerRedirectUrl: '/epmsproxy/public/api/employers/redirect_url',
			getSystemEmail: '/epmsproxy/public/api/members/email',
		},
		profileApi: {
			baseUrl: '/epmsproxy/api/members',
			bookingSimplifiedToken: '/epmsproxy/public/api/members/profile',
			pwEligibility: '/public/api/pulse/wellness/eligibility',
			forgotEmail: '/epmsproxy/public/api/members/forgot-email',
			primaryInfo: '/epmsproxy/public/api/patient/profile',
		},
		profileEmailsApi: {
			baseUrl: '/epmsproxy/api/members',
		},
		referralApi: {
			getCurrentReferrals: '/epmsproxy/api/referrals',
			saveReferral: '/epmsproxy/api/referrals',
			getPastReferrals: '/epmsproxy/api/referrals/past-referrals',
			getInsurancePlans: '/epmsproxy/api/referrals/insurance-plans',
			getInsuranceTypes: '/epmsproxy/api/referrals/insurance-types',
			saveAppointment: '/epmsproxy/api/referrals/appointments',
			getSpecialties: '/epmsproxy/api/referrals/specialties',
			validateReferrer: '/epmsproxy/public/api/members/profile/tag',
			submitReferree: '/epmsproxy/public/api/members/promotion/referral',
		},
		dashboardApi: {
			getDashboard: '/dashboard',
		},
		healthMentorApi: {
			appointments: '/epmsproxy/api/health-mentor/appointments',
			getAppointmentSlots: '/epmsproxy/api/health-mentor/appointment-slots',
			getPatientContactInfo: '/epmsproxy/api/health-mentor/patient/contact',
			getPatientMentorInfo: '/epmsproxy/api/health-mentor/patient/mentor',
			getOnsiteLocations: '/epmsproxy/api/health-mentor/on-site-locations',
			bookAppointment: '/epmsproxy/api/health-mentor/appointments/book',
			confirmAppointment: '/epmsproxy/api/health-mentor/appointments/confirm',
			rescheduleAppointment: '/epmsproxy/api/health-mentor/appointments/reschedule',
			getHapInfo: '/epmsproxy/api/health-mentor/hap-info',
			getHapInfoToken: '/epmsproxy/booking-simplified/pulse/wellness/hap-info',
			getActionPlan: '/pulse/wellness/action-plan/report',
			getCoachingStatus: '/pulse/wellness/status',
			registerCoachingLab: '/pulse/wellness/lab/register',
			kitActivation: '/pulse/wellness/activation',
		},
		messagingApi: {
			getMessages: '/epmsproxy/api/message',
			getMessageCount: '/epmsproxy/api/message/count',
		},
		healthAssessmentApi: {
			getGeneralInformation: '/epmsproxy/health-history/general-information',
			getMedicalHistory: '/epmsproxy/health-history/medical-history',
			getLifestyle: '/epmsproxy/health-history/life-style',
			saveGeneralInformation: '/epmsproxy/health-history/general-information',
			saveMedicalHistory: '/epmsproxy/health-history/medical-history',
			saveLifestyle: '/epmsproxy/health-history/lifestyle',
			getBaseURL: '/epmsproxy/health-history/',
			getFinalSaveURL: '/epmsproxy/health-history/finish',
			getAssessmentVersion: '/epmsproxy/health-history/version',
		},
		pulseVirtualApi: {
			registerNoToken: '/epmsproxy/api/pv-registration/register',
			registerToken: '/epmsproxy/pulse-virtual/api/register',
			saveRegistrationDetailsNoToken: '/epmsproxy/api/pv-registration/saveRegistrationDetails',
			saveRegistrationDetailsToken: '/epmsproxy/pulse-virtual/api/saveRegistrationDetails',
			updateRegistrationDetailsNoToken: '/epmsproxy/api/pv-registration/updateRegistrationDetails',
			updateRegistrationDetailsToken: '/epmsproxy/pulse-virtual/api/updateRegistrationDetails',
			consultation: '/epmsproxy/api/pv-registration/consult',
			consultationToken: '/epmsproxy/pulse-virtual/api/consult',
			labOrder: '/epmsproxy/api/pv-registration/lab-order',
			labOrderToken: '/epmsproxy/pulse-virtual/api/lab-order',
			labStatus: '/epmsproxy/api/pv-registration/lab/status',
			labStatusToken: '/epmsproxy/pulse-virtual/api/lab/status',
			getPvCancelledAppt: '/epmsproxy/api/pv-registration/consult/cancelled',
			validateEmailToken: '/epmsproxy/pulse-virtual/api/email',
			validateEmailNoToken: '/epmsproxy/api/pv-registration/email',
			activateKitToken: '/epmsproxy/pulse-virtual/api/activation',
			activateKitNoToken: '/epmsproxy/api/pv-registration/activation',
		},
		pulseWellnessApi: {
			appointment: '/pulse/wellness/consult/appointment',
			appointmentToken: '/pulse/wellness/api/consult/appointment',
			coaches: '/pulse/wellness/coach/all-coaches',
			coachesToken: '/pulse/wellness/api/coach/all-coaches',
			consultation: '/pulse/wellness/consult',
			consultationToken: '/pulse/wellness/api/consult',
			checkEligibility: '/pulse/wellness/eligibility',
			checkEligibilityToken: '/pulse/wellness/api/eligibility',
			getTimeslots: '/pulse/wellness/consult/timeslots',
			getTimeslotsToken: '/pulse/wellness/api/consult/timeslots',
			questionnaire: '/pulse/wellness/questionnaire',
			validateEmail: '/pulse/wellness/email',
			validateEmailToken: '/pulse/wellness/api/email',
			upcomingConsultations: '/pulse/wellness/consult/upcoming',
			actionRequiredConsultations: '/pulse/wellness/consult/action-required',
			pastConsultations: '/pulse/wellness/consult/past',
			cancelConsultation: (consultID) =>{ return `/pulse/wellness/consult/${consultID}/cancel`;},
			cancellationReasons: '/pulse/wellness/consult/cancellation-reasons'
		},
		messaging: {
			getMessages: '/epmsproxy/api/message/count',
		},
		healthSummary: {
			getHealthSumamry: '/epmsproxy/api/health-summary',
		},
		dashboard: {
			getNewsFeeds: '/dashboard/newsfeeds',
			getPECount: '/dashboard/pe-status',
			getHaStatus: '/dashboard/ha-status',
			tutorial: {
				status: '/dashboard/tutorial_status',
			},
			confirmMemberAccountInfo: '/dashboard/confirm-profile',
			vaxpass: {
				profile: '/dashboard/vaxpass/profile',
				getVaccines: '/dashboard/vaxpass/vaccinations',
				getConsent: '/dashboard/vaxpass/consent',
				statusDetail: '/dashboard/vaxpass/status',
				getStates: '/epmsproxy/api/locations/states-map',
			},
		},
		healthRecord: {
			getImmunization: '/epmsproxy/api/immunization',
			getHealthDocuments: '/epmsproxy/api/health-documents',
			getBiometricResults: '/epmsproxy/api/biometric-reports',
			getRecordReleases: '/consent/patient/mrr/list',
			submitRecordRelease: '/consent/patient/mrr',
			viewRecordRelease: '/consent/patient/mrr/doc',
		},
		eheandme: {
			login: '/epmsproxy/public/api/phr/login',
			authenticate: '/user/login',
			email: '/myehe/email',
			username: '/myehe/username',
			forgotUsernameUrl: '/user/forgotuser',
			userLoginSession: '/epmsproxy/api/user/session',
		},
		migration: {
			status: '/epmsproxy/public/api/patient/migration-status',
			checkExistingUser: '/epmsproxy/public/api/patient/check-email',
		},
		patient: {
			get_info_by_tag: '/epmsproxy/public/api/patient/info-by-tag',
		},
		digitalBanner: {
			getDigitalBannerEligibility: '/epmsproxy/api/eligibility/pulse-digital',
		},
		newHealthAssessmentApi: {
			personalInfo: '/health-assessment/patient/general-info/personal-info',
			emergencyContact: '/health-assessment/patient/general-info/emergency-contact',
			livingWill: '/health-assessment/patient/general-info/living-will',
			ethnicBackgroundLookup: '/health-assessment/lookup/patient/ethnic-background-list',
			maritalStatusLookup: '/health-assessment/lookup/patient/marital-status-list',
			educationLevelLookup: '/health-assessment/lookup/patient/education-level-list',
			primaryCarePhysician: '/health-assessment/patient/general-info/primary-care',
			specialists: '/health-assessment/patient/general-info/medical-specialists',
			healthAssessment: '/health-assessment/patient/health-assessment',
			medicalConditionsLookup: '/health-assessment/lookup/patient/medical-conditions-options',
			pastPresentMedicalConditions: '/health-assessment/patient/medical-history/past-present-conditions',
			surgicalHistory: '/health-assessment/patient/medical-history/surgical-history',
			medications: '/health-assessment/patient/medical-history/medications',
			allergies: '/health-assessment/patient/medical-history/allergies',
			screenings: '/health-assessment/patient/medical-history/screenings',
			vaccinations: '/health-assessment/patient/medical-history/vaccinations',
			menHealth: '/health-assessment/patient/medical-history/men-health',
			womenHealth: '/health-assessment/patient/medical-history/women-health',
			familyHistory: '/health-assessment/patient/medical-history/family-history',
			safety: '/health-assessment/patient/life-style/safety',
			substanceAbuse: '/health-assessment/patient/life-style/substance-abuse',
			howYouSleep: '/health-assessment/patient/life-style/how-you-sleep',
			howYouThink: '/health-assessment/patient/life-style/how-you-think',
			howYouEat: '/health-assessment/patient/life-style/how-you-eat',
			howYouMove: '/health-assessment/patient/life-style/how-you-move',
			workLife: '/health-assessment/patient/life-style/work-life',
			healthGoals: '/health-assessment/patient/life-style/health-goals',
			satisfiedWithSleepLookup: '/health-assessment/lookup/patient/satisfied-with-sleep-options',
			difficultyWithSleepLookup: '/health-assessment/lookup/patient/difficulty-with-sleeping-options',
			howYouThinkProblemOptionsLookup: '/health-assessment/lookup/patient/bothered-by-problems-options',
			considerMyselfRatingLookup: '/health-assessment/lookup/patient/consider-myself-rating-options',
			characterizationDescribeRatingOptionsLookup:
				'/health-assessment/lookup/patient/characterization-describe-rating-options',
			thinkReactionOptions: '/health-assessment/lookup/patient/think-reaction-options',
			healthGoalsLookup: '/health-assessment/lookup/patient/health-goals-plan-options',
			menHealthConcernsLookup: '/health-assessment/lookup/patient/men-health-concern-options',
			womenHealthConcernsLookup: '/health-assessment/lookup/patient/women-health-concern-options',
			alcoholDrinksPerDayLookup: '/health-assessment/lookup/patient/alcohol-drinks-per-day-options',
			alcoholDrinksMoreThanSixLookup: '/health-assessment/lookup/patient/alcohol-drinks-more-than-six-options',
			alcoholFrequencyLookup: '/health-assessment/lookup/patient/alcohol-drinking-frequency-options',
			safetyOptions: '/health-assessment/lookup/patient/safety-options',
			relativeOptions: '/health-assessment/lookup/patient/relative-options',
			diagnosisOptions: '/health-assessment/lookup/patient/diagnosis-options',
			painDiscomfortOptions: '/health-assessment/lookup/patient/pain-discomfort-area-options',
			workingHoursLookup: '/health-assessment/lookup/patient/weekly-hours-of-working-options',
			nightsAwayFromHomeLookup: '/health-assessment/lookup/patient/monthly-nights-away-from-home-options',
			markComplete: '/health-assessment/patient/mark-complete',
			genderIdentityLookup: '/health-assessment/lookup/patient/gender-identity-list',
			preferredTitleLookup: '/health-assessment/lookup/patient/preferred-title-list',
			preferredPronounsLookup: '/health-assessment/lookup/patient/preferred-pronouns-list',
			hoursMissedFromWorkLookup: '/health-assessment/lookup/patient/hours-missed-from-work-options',
			healthProblemAbilityAffectLookup: '/health-assessment/lookup/patient/health-problems-affect-options',
		},
		resources: {
			activationVideo: 'https://ehe.health/br_2022q1_activationvideo_60sec_v2_rd01-mp4',
		},
	};

	schedular = {
		subProcedures: {
			/** User can see included sub-procedures when booking */
			hide: true,

			/** User/Patient can sub-procedures (Add/Remove) */
			userCanUpdate: false,

			/** Whether or not all sub-procedures are included by default */
			selectAllByDefault: true,

			/** Whether or not selected sub-procedures are included in API call(s) when searching slots \
			 * If `false`, sub-procedure slot will be removed from selected InventorySlot while calling API(s) to get time-slots. */
			includeWhileFetchingSlots: false,

			/** When sub-procedure is added/removed, call API to get new slots if required. \
			 * If `false`, API will NOT be called to fetch new time-slots containing sub-procedure staff slot update. */
			apiCallOnUpdate: false,

			/** Include selected sub-procedures when continuing with selected time-slot \
			 * If `false`, sub-procedure slots will not be sent to backend while confirming appointment slot. */
			// includeStaffSlotsOnConfirm: false,

			/* Mammogram as a sub-procedure is controlled based on SDM logic,
				so it will be visible when eligibility is right (`hideAll` doesn't affect it),
				and user is always allowed to update it (`userCanUpdate` doesn't affect it),
				and is included automatically if eligible (`selectAllByDefault` doesn't affect it),
				and `includeMammoWhileFetchingSlots` is used if `includeWhileFetchingSlots = false` for controlling fetching time-slots behavior
				and `apiCallOnMammoUpdate` is used if `apiCallOnUpdate = false` for controlling API call on mammogram sub-procedure update behavior
				and `includeMammoStaffSlotsOnConfirm` is used if `includeStaffSlotsOnConfirm = false` for controlling appointment confirmation behavior
				and if eligible, use can still remove it.
			*/
			/** Whether or not to show message to users if mammogram is removed due to SDM non-eligibility of the facility */
			showMammogramRemovalMessage: false,

			/** When mammogram sub-procedure is added/removed, call API to get new slots if required. \
			 * This is used if `includeWhileFetchingSlots = false` \
			 * If this is `false`, mammogram sub-procedure slot will be removed from selected InventorySlot while calling API(s) to get time-slots. */
			includeMammoWhileFetchingSlots: true,

			/** When mammogram sub-procedure is added/removed, call API to get new slots if required. \
			 * This is used if `apiCallOnUpdate = false` \
			 * If `false`, API will NOT be called to fetch new time-slots containing sub-procedure staff slot update. */
			apiCallOnMammoUpdate: false,

			/** Include selected mammogram sub-procedures when continuing with selected time-slot \
			 * This is used if `includeStaffSlotsOnConfirm = false` \
			 * If `false`, mammogram sub-procedure slot will not be sent to backend while confirming appointment slot. */
			// includeMammoStaffSlotsOnConfirm: false,

			/** Use staff-slot of main procedure time-slot for mammogram when `includeMammoWhileFetchingSlots = false` \
			 * If `true` and when `includeMammoWhileFetchingSlots = false` main procedure staff slot will be copied into mammogram staff slot for booking mammogram sub-procedure. */
			useMainProcStaffSlotForMammo: false,

			/** Whether or not to show a message that says previously selected inventory is not available */
			showSelectedInventorySlotNotAvailableMessage: false,
		},
	};

	userLoginSession = Object.freeze({
		sessionCheckIntervalSeconds: 15,
		sessionCheckIntervalVarName: 'sessionCheckInterval',
	});

	TOKEN_NAME = 'auth';

	toasterConfig = {
		closeButton: true,
		positionClass: 'toast-top-right',
		tapToDismiss: true,
		// 'progressBar': true,
		timeOut: 5000,
	};

	successMsgConfig = {
		closeButton: false,
		debug: false,
		newestOnTop: false,
		progressBar: false,
		positionClass: 'toast-top-right',
		preventDuplicates: false,
		onclick: null,
		showDuration: 300,
		hideDuration: 800,
		timeOut: 3500,
		extendedTimeOut: 1000,
		showEasing: 'swing',
		hideEasing: 'linear',
		showMethod: 'fadeIn',
		hideMethod: 'fadeOut',
	};

	content = {
		errorTitle: {
			ERRTIT001: 'SUCCESS',
			ERRTIT002: 'ALERT',
			ERRTIT003: 'INFO',
			ERRTIT004: 'WARNING',
			ERRTIT005: 'ERROR',
		},
		errorType: {
			ERRTYP001: 'success',
			ERRTYP002: 'alert',
			ERRTYP003: 'info',
			ERRTYP004: 'warning',
			ERRTIT005: 'error',
		},
		referral: {
			MSGREF001: 'Your referral request has been sent.',
			MSGREF002: 'Please select a provider before making referral request.',
			MSGREF003: 'Please enter your reason for request and insurance details before making referral request.',
			MSGREF004: 'Please re-enter valid date and time for referral request.',
			MSGREF005: 'Your appointment request has been sent.',
			MSGREF006: 'Your appointment request was not sent. Please contact Referrals',
		},
		profile: {
			MSGPRO001: 'You have successfully updated your profile information.',
			MSGPRO002: 'Your profile changes were not saved. Please contact memberservices@ehe.health or 800.362.8671',
			MSGPRO003: 'Your additional email has been deleted.',
			MSGPRO004: 'Your email update was not saved. Please contact memberservices@ehe.health or 800.362.8671',
			MSGPRO005: 'You have successfully updated your email information.',
			MSGPRO006:
				'We were not able to send a verification code. Please contact memberservices@ehe.health or 800.362.8671',
			MSGPRO007: 'Your email has been verified.',
			MSGPRO008: 'You have successfully added an additional email.',
			MSGPRO009: 'The code you entered is invalid. Please enter again.',
			MSGPRO010: 'You have successfully reset your password.',
			MSGPRO011: 'The password you entered is incorrect. Please try again.',
			MSGPRO012: 'Your password update was not saved. Please contact memberservices@ehe.health or 800.362.8671',
			MSGPRO013: 'You have successfully changed your primary email.',
			MSGPRO014: 'Your email update was not saved. Please contact memberservices@ehe.health or 800.362.8671',
		},
		messaging: {
			MSGMSG001: 'Your message has been sent.',
			MSGMSG002: 'Your message has been deleted.',
			MSGMSG003: 'Your reply has been sent.',
		},
		health_coach: {
			MSGHM001: 'Your appointment has been canceled. ',
			MSGHM002: 'Your message has been sent.',
			MSGHM003: 'Your appointment has been rescheduled.',
			MSGHM004: 'Your appointment has been scheduled.',
		},
		health_history: {
			MSGHH001: 'Your Health Assessment has been submitted. Please feel free to update the form until your exam date.',
		},
		auth: {
			MSGAUTH001: 'Verification code has been sent successfully',
			MSGAUTH002: 'You have successfully reset your password.',
		},
		schedule: {
			MSGSCH001: 'Your appointment request has been sent',
			MSGSCH002: 'No Inventory',
			MSGSCH003: 'Not allowed to book at this facility for this procedure',
		},
		fileUpload: {
			MSGFILEUP001: 'Not an allowed file type.',
			MSGFILEUP002: 'File too large. Limit for upload is 2MB.',
			MSGFILEUP003: 'Not enough space remaining.',
		},
		login: {
			MSGLOGIN001: 'It looks like you previously registered for EHE&Me. Please use your EHE&Me username to sign-in.',
		},
	};

	otherDocuments = {
		UPLOAD_LIMIT: 20_971_520,
		UPLOAD_LIMIT_PER_FILE: 2_097_152,
	};

	exceptions = {
		cognito: {
			RESET_REQUIRED: 'PasswordResetRequiredException',
			USER_NOT_FOUND: 'UserNotFoundException',
			LIMIT_EXCEEDED: 'LimitExceededException',
			NOT_AUTHORIZED: 'NotAuthorizedException',
			CODE_MISMATCH: 'CodeMismatchException',
			INVALID_PARAMETER: 'InvalidParameterException',
			CODE_EXPIRED: 'ExpiredCodeException',
			ATTEMPTS_EXCEEDED: 'Password attempts exceeded',
		},
		service: {
			SESSION_EXPIRY: 'The security token included in the request is expired',
			SERVER_ERROR: 'Oops! an error occurred. Please try again',
			ACCOUNT_NOT_VERIFIED: 'We could not verify your account',
			USER_ALREADY_ACTIVATED_ON_EHE_ME: 'User is activated on EHE&Me',
			EMAIL_TAKEN_UP: 'System Email ID is already used by another activated user',
			EMAIL_DOES_NOT_MATCH: "Provided email doesn't match with the email in the system",
			USER_ALREADY_AVAILABLE: 'User already available',
			EMAIL_ALREADY_EXISTS: 'Email ID is already used by another activated user',
			NO_MATCH: 'NoMatch',
			INVALID_VERIFICATION_CODE: 'Invalid verification code',
			EMAIL_CLAIMED_BY_PRIMARY: 'User must register with a different email from primary account holder',
			appointments: {
				REFRESH_REQUIRED: 'It looks likes there was an error on this page, please refresh your page.',
				SLOT_BOOKED: 'Slot already booked.',
			},
			EMPLOYER_NOT_ACTIVATED_IN_MYEHE: 'Employer is not active in myEHE',
			USER_IS_ALREADY_MIGRATED: 'Migration initiated for user',
			MATCH_NOT_FOUND: 'No match found',
			INVALID_REQUEST: 'Invalid request',
			SIMPLIFIED_NO_PROCEDURE:
				'You have no available procedure at this time. Please check back at a later date.Please contact us at 800.362.8671 for more information.',
		},
	};

	print = {
		css: {
			filePath: '../../assets/styles/common/bootstrap.min.css', // added to style the table while printing
		},
	};

	cognitoConfig = {
		region: 'us-east-1',
		unAuthIdentityPoolId: 'us-east-1:cad4968f-036c-4afd-b83d-81503f4423c1',
	};

	timeOffsetMsgs = {
		sigNotCurrent: 'Signature not yet current',
		sigExpired: 'Signature expired',
		awsDateTimeFormat: 'YYYYMMDDThhmmssZ',
	};

	availableTranslationLanguages = ['en', 'es'];

	constructor() {
		this.loadLocalStorage_SubProcedureConfig();
		console.log('config_SubProc:', this.schedular.subProcedures);
	}

	loadLocalStorage_SubProcedureConfig() {
		const ls_SubProcConfigKey = 'config_SubProc';
		const ls_SubProcConfigStr = localStorage.getItem(ls_SubProcConfigKey);
		if (ls_SubProcConfigStr) {
			this.schedular.subProcedures = JSON.parse(ls_SubProcConfigStr);
		} else {
			localStorage.setItem(ls_SubProcConfigKey, JSON.stringify(this.schedular.subProcedures, null, '\t'));
		}
	}
}

// Flag declaration to suppress components using the featureFlag attribute defined in the routing module
// True -> Feature suppressed
export const suppressingFlags = {
	AdditionalHealthDocuments: true,
	MovementScreen: true,
	privacyShield: true,
};
