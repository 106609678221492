import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from 'src/app/config/config';
import { Environment } from 'src/app/config/environment';
import { METHOD_TYPE } from 'src/app/constants/service.constants';
import { ServiceRouter } from './http-service-router.service';
import { EmailValidationResponse } from 'src/app/beans/pulse-wellness/email-validation.bean';
import { AppointmentRequest, CancellationReasonBean, EligibilityResponse, PwTimeslotResponse } from 'src/app/beans/pulse-wellness/pulse-wellness.bean';
import { JwtHelper } from './jwt-helper.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PulseWellnessService {

  constructor(
    private serviceRouter: ServiceRouter,
		private environment: Environment,
		private config: Config,
		private jwtService: JwtHelper
  ) { }

  	/* If the current date is after 24 hours before scheduled date, then don't allow cancel */
	allowAppointmentCancel(scheduledDate: string) {
		return moment().isBefore(moment(scheduledDate).subtract(1, 'days'));
	}

	bookAppointment(requestUrl: string, method: string, body: AppointmentRequest): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().PULSE_WELLNESS_URL + this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN,
			requestUrl,
			method,
			body,
			this.environment.getEnvironment().PULSE_WELLNESS_URL,
			this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN
		);
	}

	checkEligibility(): Observable<EligibilityResponse> {
		let checkEligibilityUrl = this.jwtService.getEpmsId() ? this.config.url.pulseWellnessApi.checkEligibility
			: this.config.url.pulseWellnessApi.checkEligibilityToken;
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().PULSE_WELLNESS_URL + this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN,
			checkEligibilityUrl,
			this.jwtService.getEpmsId() ? METHOD_TYPE.SECURED_GET : METHOD_TYPE.GENERAL_GET,
			'',
			this.environment.getEnvironment().PULSE_WELLNESS_URL,
			this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN
		);
	}

	getCoaches(isSimplified: boolean): Observable<any> {
		let requestUrl = !isSimplified ? this.config.url.pulseWellnessApi.coaches 
							: this.config.url.pulseWellnessApi.coachesToken;

		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().PULSE_WELLNESS_URL + this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN,
			requestUrl,
			!isSimplified ? METHOD_TYPE.SECURED_GET : METHOD_TYPE.GENERAL_GET,
			'',
			this.environment.getEnvironment().PULSE_WELLNESS_URL,
			this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN
		);
	}

	getCancellationReasons(): Observable<any> {
			return this.serviceRouter
				.makeRequest(
					this.environment.getEnvironment().PULSE_WELLNESS_URL + this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN,
					this.config.url.pulseWellnessApi.cancellationReasons,
					METHOD_TYPE.SECURED_GET,
					'',
					this.environment.getEnvironment().PULSE_WELLNESS_URL,
					this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN
				);
	}

  getTimeslots(start: string, end: string, timezoneID: number, isSimplified: boolean, coachID?: number | undefined, is_followup_consult?: boolean): Observable<PwTimeslotResponse> {
    let params = new HttpParams()
      .set('start', start)
      .set('end', end)
      .set('timezone_id', timezoneID)
      .set('is_followup_consult', is_followup_consult !== undefined && is_followup_consult !== null ? is_followup_consult : false);

		if(coachID && coachID > -1) {
			params = params.append('coach_id', coachID);
		}

	  let requestUrl = !isSimplified ? this.config.url.pulseWellnessApi.getTimeslots 
							: this.config.url.pulseWellnessApi.getTimeslotsToken;

		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().PULSE_WELLNESS_URL + this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN,
			`${requestUrl}?${params.toString()}`,
			!isSimplified ? METHOD_TYPE.SECURED_GET : METHOD_TYPE.GENERAL_GET,
			'',
			this.environment.getEnvironment().PULSE_WELLNESS_URL,
			this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN
		);
	}

	getTimezone(): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
			this.jwtService.getEpmsId() ? this.config.url.healthMentorApi.getHapInfo : this.config.url.healthMentorApi.getHapInfoToken,
			this.jwtService.getEpmsId() ? METHOD_TYPE.SECURED_GET : METHOD_TYPE.GENERAL_GET,
			''
		)
	}

	cancelConsultation(consultID: number, cancelReason : CancellationReasonBean): Observable<any>{
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().PULSE_WELLNESS_URL + this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN,
			this.config.url.pulseWellnessApi.cancelConsultation(consultID),
			METHOD_TYPE.SECURED_POST,
			cancelReason,
			this.environment.getEnvironment().PULSE_WELLNESS_URL,
			this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN
		)
	}

	validateEmail(emailId: string): Observable<EmailValidationResponse> {
		const isSimplified = this.jwtService.getEpmsId() ? false : true;
		let requestUrl = !isSimplified ? this.config.url.pulseWellnessApi.validateEmail
			: this.config.url.pulseWellnessApi.validateEmailToken;
		let requestMethod = !isSimplified ? METHOD_TYPE.SECURED_GET : METHOD_TYPE.GENERAL_GET;

		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().PULSE_WELLNESS_URL + this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN,
			`${requestUrl}/${emailId}/validate`,
			requestMethod,
			'',
			this.environment.getEnvironment().PULSE_WELLNESS_URL,
			this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN
		);
	}
	
	getPastConsultations(): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().PULSE_WELLNESS_URL,
			this.config.url.pulseWellnessApi.pastConsultations,
			METHOD_TYPE.SECURED_GET,
			'',
			this.environment.getEnvironment().PULSE_WELLNESS_URL,
			this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN
		);
	}

		
	getUpcomingConsultations(): Observable<any> {
		return this.serviceRouter.makeRequest(
			this.environment.getEnvironment().PULSE_WELLNESS_URL,
			this.config.url.pulseWellnessApi.upcomingConsultations,
			METHOD_TYPE.SECURED_GET,
			'',
			this.environment.getEnvironment().PULSE_WELLNESS_URL,
			this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN
		);
	}

	getActionRequiredConsultations(): Observable<any>{
		return this.serviceRouter.makeRequest(
		this.environment.getEnvironment().PULSE_WELLNESS_URL,
		this.config.url.pulseWellnessApi.actionRequiredConsultations,
		METHOD_TYPE.SECURED_GET,
		'',
		this.environment.getEnvironment().PULSE_WELLNESS_URL,
		this.environment.getEnvironment().PULSE_WELLNESS_DOMAIN)
	}
}
